import { createReducer } from '@reduxjs/toolkit';
import {
  upload,
  uploadAssetSuccess,
  uploadAssetFailure,
  uploadComplete,
  toggleUploadManager,
  endUploadSequence,
  initialAssetUpload,
  cancelAssetUpload,
  retryAssetUpload,
  cancelAssetUploads,
  removeAssetUploads,
  removeFileProcessing,
  addFilesProcessing,
  addToPinnedUploads,
  removeFromPinnedUploads,
  clearPinnedUploads,
  updatePinnedUpload,
} from './actions';
import { filter } from 'lodash';
import { Asset } from '@integration-frontends/integration/core/model';

export const AssetUploadStatuses = [
  'initialUpload',
  'retrying',
  'failed',
  'success',
  'cancelled',
] as const;
export type AssetUploadStatus = typeof AssetUploadStatuses[number];

export type AssetUpload = {
  fileUpload: FileUpload;
  id: string;
  collectionId: string;
  sectionId: string;
  status: AssetUploadStatus;
  statusHistory: AssetUploadStatus[];
  error?: string;
  uploadedAssetData?: {
    id: string;
    name: string;
  };
};

export const UploadLocations = ['landingPage', 'showPage'] as const;
export type UploadLocation = typeof UploadLocations[number];

export interface UploadManager {
  open: boolean;
  location: UploadLocation;
}
export type FileUpload = {
  file: File;
  retry: boolean;
  valid?: boolean;
  id?: string;
};

export const MAX_UPLOAD_SIZE = 15000000000;
export const MAX_FILE_SIZE = 1000000000;
export const MAX_UPLOAD_COUNT = 100;

export interface ContentLibraryUploadAssetsState {
  loading: boolean;
  assetUploads?: AssetUpload[];
  uploadManagerOpen?: boolean;
  currentAssetUploadIndex: number;
  currentAssetsInUpload: string[];
  uploadSequenceId: string;
  openManagerAfterUpload?: boolean;
  successfulAssetUploadInSequence?: boolean;
  filesProcessing?: string[];
  pinnedUploads?: Asset[];
}

export const uploadAssetsInitialState: ContentLibraryUploadAssetsState = {
  loading: false,
  assetUploads: [],
  uploadManagerOpen: false,
  currentAssetUploadIndex: 0,
  currentAssetsInUpload: [],
  uploadSequenceId: null,
  openManagerAfterUpload: false,
  successfulAssetUploadInSequence: false,
  filesProcessing: [],
  pinnedUploads: [],
};

const changeAssetUploadStatus = (assetUpload: AssetUpload, status: AssetUploadStatus) => {
  assetUpload.status = status;
  assetUpload.statusHistory.push(status);
};

const resetUploadState = (state: ContentLibraryUploadAssetsState) => {
  state.currentAssetUploadIndex = 0;
  state.currentAssetsInUpload = [];
  state.openManagerAfterUpload = false;
  state.successfulAssetUploadInSequence = false;
};

const resetUploadSequenceState = (state: ContentLibraryUploadAssetsState) => {
  state.uploadSequenceId = null;
  state.assetUploads = [];
  state.uploadManagerOpen = false;
};

export const CONTENT_LIBRARY_UPLOAD_ASSETS_REDUCER = createReducer(
  uploadAssetsInitialState,
  (builder) =>
    builder
      .addCase(upload, (state) => {
        if (!state.uploadSequenceId) {
          state.uploadSequenceId = crypto.randomUUID();
        }
      })
      .addCase(initialAssetUpload, (state, action) => {
        const { assetUploadId, collectionId, sectionId, fileUpload } = action.payload;
        state.assetUploads = [
          ...state.assetUploads,
          {
            id: assetUploadId,
            collectionId,
            sectionId,
            fileUpload,
            status: 'initialUpload',
            statusHistory: ['initialUpload'],
          },
        ];
        state.currentAssetsInUpload = [...state.currentAssetsInUpload, assetUploadId];
      })
      .addCase(uploadAssetSuccess, (state, action) => {
        const { assetUploadId } = action.payload;
        const assetUpload = state.assetUploads.find((upload) => upload.id === assetUploadId);
        changeAssetUploadStatus(assetUpload, 'success');
        state.successfulAssetUploadInSequence = true;
        state.currentAssetUploadIndex++;
      })
      .addCase(uploadAssetFailure, (state, action) => {
        const { assetUploadId, error, valid } = action.payload;
        const assetUpload = state.assetUploads.find((upload) => upload.id === assetUploadId);
        changeAssetUploadStatus(assetUpload, 'failed');
        assetUpload.error = error;
        assetUpload.fileUpload.valid = valid;
        state.openManagerAfterUpload = true;
        state.currentAssetUploadIndex++;
      })
      .addCase(cancelAssetUploads, (state, action) => {
        const { assetUploadIds } = action.payload;
        state.assetUploads = state.assetUploads.map((assetUpload) => {
          if (assetUploadIds.includes(assetUpload.id)) {
            changeAssetUploadStatus(assetUpload, 'cancelled');
          }
          return assetUpload;
        });
      })
      .addCase(removeAssetUploads, (state, action) => {
        const { assetUploadIds } = action.payload;
        state.assetUploads = state.assetUploads.filter(
          (assetUpload) => !assetUploadIds.includes(assetUpload.id),
        );
      })
      .addCase(uploadComplete, (state) => {
        const unresolvedAssetUploads = filter(
          state.assetUploads,
          (assetUpload) =>
            assetUpload.statusHistory.includes('failed') &&
            assetUpload.statusHistory[assetUpload.statusHistory.length - 1] !== 'cancelled',
        );
        resetUploadState(state);
        // if all uploads are resolved, reset upload sequence state
        if (unresolvedAssetUploads.length === 0) {
          resetUploadSequenceState(state);
        }
      })
      .addCase(addFilesProcessing, (state, action) => {
        const { assetIds } = action.payload;
        state.filesProcessing = [...state.filesProcessing, ...assetIds];
      })
      .addCase(removeFileProcessing, (state, action) => {
        const { assetIds } = action.payload;
        state.filesProcessing = state.filesProcessing.filter((id) => !assetIds.includes(id));
      })
      .addCase(retryAssetUpload, (state, action) => {
        const { assetUploadId } = action.payload;
        const assetUpload = state.assetUploads.find((upload) => upload.id === assetUploadId);
        changeAssetUploadStatus(assetUpload, 'retrying');
        // if the assetUpload does not exist in the currentAssetsInUpload array, add it
        if (!state.currentAssetsInUpload.includes(assetUploadId)) {
          state.currentAssetsInUpload = [...state.currentAssetsInUpload, assetUploadId];
        }
      })
      .addCase(cancelAssetUpload, (state, action) => {
        const { assetUploadId } = action.payload;
        const assetUpload = state.assetUploads.find((upload) => upload.id === assetUploadId);
        changeAssetUploadStatus(assetUpload, 'cancelled');
      })
      .addCase(toggleUploadManager, (state, action) => {
        const { open } = action.payload;
        state.uploadManagerOpen = open;
      })
      .addCase(endUploadSequence, (state) => {
        resetUploadState(state);
        resetUploadSequenceState(state);
      })
      .addCase(addToPinnedUploads, (state, action) => {
        const { asset } = action.payload;
        state.pinnedUploads = [asset, ...state.pinnedUploads];
      })
      .addCase(removeFromPinnedUploads, (state, action) => {
        const { assetIds } = action.payload;
        state.pinnedUploads = state.pinnedUploads.filter((asset) => !assetIds.includes(asset.id));
      })
      .addCase(clearPinnedUploads, (state) => {
        state.pinnedUploads = [];
      })
      .addCase(updatePinnedUpload, (state, action) => {
        const { assetId, name } = action.payload;
        state.pinnedUploads = state.pinnedUploads.map((asset) => {
          if (asset.id === assetId) {
            asset.name = name;
          }
          return asset;
        });
      }),
);
