import { t } from '@lingui/macro';
import { call, takeEvery } from 'redux-saga/effects';
import { selectAllAssetsError, selectAllAssetsInit, selectAllAssetsSuccess } from '../actions';
import { searchAssetsSelectors } from '../../../selectors';
import { put, select } from 'typed-redux-saga';
import {
  ASSET_REPO_TOKEN,
  Brandfolder,
  Collection,
  DEFAULT_SORT_OPTIONS,
  IAssetRepo,
  IMediaTypeSupportService,
  ListOptions,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  SearchParameters,
  SortOptions,
  addFileTypeFilters,
  getMediaTypesExtensions,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { clearToasts, closeToast, createToast } from '@integration-frontends/common/notifications';
import { DEFAULT_SELECTED_ASSETS_LIMIT } from '../reducer';

const handler = function* () {
  const container: Brandfolder | Collection = yield select(searchAssetsSelectors.container);
  const searchParams = yield select(searchAssetsSelectors.searchParams);
  const sortOptions = yield select(searchAssetsSelectors.sortOptions);
  const loadingToastId = `select-all-assets-init-loading-${Date.now()}`;
  const successSelectedToastId = `select-all-assets-init-success-${Date.now()}`;

  yield put(clearToasts())
  try {
    yield put(
      createToast({
        id: loadingToastId,
        type: 'info',
        toastContent: { contentText: t`Selecting all files...` },
      })
    );
    const response = yield call(fetchContainerAssets, searchParams, sortOptions);
    const assets = response.data.assets;
    const totalAssets = response.totalCount;
    const loadedAssetsContent = totalAssets > DEFAULT_SELECTED_ASSETS_LIMIT ? t`1000 of ${totalAssets} files selected.` : t`All files selected.`;
    yield put(selectAllAssetsSuccess({ assets }));
    yield put(closeToast({ id: loadingToastId }))
    yield put(
      createToast({
        id: successSelectedToastId,
        type: 'success',
        toastContent: { contentText: loadedAssetsContent },
      })
    );
  } catch (error) {
    yield put(selectAllAssetsError({ error }));
  }

  function fetchContainerAssets(searchParams: SearchParameters, sortOptions: SortOptions) {
    const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
    const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
      MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
    );
    const options: ListOptions = {
      searchParams: addFileTypeFilters(
        searchParams,
        getMediaTypesExtensions(mediaTypeSupportService.getAllowedMediaTypes()),
      ),
      pagination: { perPage: DEFAULT_SELECTED_ASSETS_LIMIT },
      sort: sortOptions || DEFAULT_SORT_OPTIONS,
    };

    return assetRepo.listContainerAssetsBase(container, options);
  }
};

export function* selectAllAssetsInitEffects() {
  yield takeEvery(selectAllAssetsInit, handler);
}
