import { createReducer } from '@reduxjs/toolkit';
import {
  deselectAllAssets,
  selectAllAssetsError,
  selectAllAssetsInit,
  selectAllAssetsSuccess,
  shiftSelectAssetsSuccess,
  toggleAssetSelection,
} from './actions';
import { Asset } from '@integration-frontends/integration/core/model';

export const DEFAULT_SELECTED_ASSETS_LIMIT = 1000;

export interface SelectAssetState {
  selectedAssets: Asset[];
  loading: boolean;
}

export const selectAssetInitialState: SelectAssetState = {
  selectedAssets: [],

  loading: false,
};

export const SELECT_ASSET_REDUCER = createReducer(selectAssetInitialState, (builder) => {
  builder
    .addCase(selectAllAssetsInit, (state) => {
      state.loading = true;
    })
    .addCase(selectAllAssetsError, (state) => {
      state.loading = false;
    })
    .addCase(selectAllAssetsSuccess, (state, action) => {
      state.loading = false;
      state.selectedAssets = action.payload.assets;
    })
    .addCase(deselectAllAssets, (state) => {
      state.selectedAssets = [];
    })
    .addCase(toggleAssetSelection, (state, action) => {
      const index = state.selectedAssets.findIndex((asset) => asset.id === action.payload.asset.id);

      if (index !== -1) {
        state.selectedAssets = state.selectedAssets.filter(
          (asset) => asset.id !== action.payload.asset.id,
        );
      } else {
        if (state.selectedAssets.length < DEFAULT_SELECTED_ASSETS_LIMIT) {
          state.selectedAssets = [...state.selectedAssets, action.payload.asset];
        }
      }
    })
    .addCase(shiftSelectAssetsSuccess, (state, action) => {
      const { assets } = action.payload;
      state.selectedAssets = assets;
    });
});
