import { ListboxOption, StandardComboboxProps, type ListboxOptionValue } from '@brandfolder/react';
import { FC } from 'react';
import { createResolvableComponent } from '../../create-resolvable-component';
import { SelectV2Props } from '@smartsheet/lodestar-core';

export type BFListboxOption = Pick<ListboxOption, 'label'> & {
  value: any;
};
export type BFStandardComboboxProps = Pick<StandardComboboxProps, 'aria-label'> &
  Pick<SelectV2Props<BFListboxOption, HTMLInputElement>, 'formatOption'> & {
    onSelection: (
      option: BFListboxOption | null,
      index?: number,
      value?: ListboxOptionValue,
    ) => void;
    options: BFListboxOption[];
    placeholder?: string;
    selectedOption?: ListboxOption;
  };

export type BFStandardComboboxComponent = FC<BFStandardComboboxProps>;
export const BF_STANDARD_COMBOBOX_COMPONENT = 'BF_STANDARD_COMBOBOX';
export const BFStandardCombobox = createResolvableComponent<BFStandardComboboxProps>(
  BF_STANDARD_COMBOBOX_COMPONENT,
);
