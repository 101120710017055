import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { Asset } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';

export const selectAllAssetsInit = createAction('SELECT_ALL_ASSETS_INIT');
export const selectAllAssetsSuccess = createAction(
  'ALL_ASSETS_SELECTED_SUCCESS',
  withPayloadType<{ assets: Asset[] }>(),
);
export const selectAllAssetsError = createAction(
  'SELECT_ALL_ASSETS_ERROR',
  withPayloadType<{ error: Error }>(),
);
export const deselectAllAssets = createAction('DESELECT_ALL_ASSETS');
export const toggleAssetSelection = createAction(
  'TOGGLE_SELECT_ASSET',
  withPayloadType<{ asset: Asset }>(),
);
export const shiftSelectAssets = createAction(
  'SHIFT_SELECT_ASSETS',
  withPayloadType<{ asset: Asset }>(),
);
export const shiftSelectAssetsSuccess = createAction(
  'SHIFT_SELECT_ASSETS_SUCCESS',
  withPayloadType<{ assets: Asset[] }>(),
);
export const showMaxSelectableAssetsToast = createAction('SHOW_MAX_SELECTABLE_ASSETS_TOAST');
