import {
  AddAssetTagsResponseData,
  ApiFetchDataResponse,
  ApiListDataResponse,
  AttachmentInputDto,
  BulkDownloadAssetsDto,
  CustomFieldKeyResponseData,
  CustomFieldKeyValueDto,
  GenericFileDto,
} from '@integration-frontends/common/brandfolder-api';
import {
  Asset,
  AssetDetails,
  AssetsListBaseResultSet,
  AssetsListResultSet,
  IAssetRepo,
  ListOptions,
  PagedResults,
  Section,
} from '..';
import { Container } from '../../container';
import {
  assetCustomFieldValuesMockData,
  assetsMockData,
  assetTagsMockData,
  attachmentsMockData,
  listCustomFieldKeysResponseMockData,
} from './data';

export class AssetRepoMock implements IAssetRepo {
  create(
    container: Container,
    sectionId: string,
    name: string,
    files: File[],
  ): Promise<ApiListDataResponse> {
    return Promise.resolve(undefined);
  }

  uppyUpload(
    sectionId: string,
    name: string,
    attachment: AttachmentInputDto,
    collectionId?: string,
  ): Promise<ApiListDataResponse> {
    return Promise.resolve(undefined);
  }

  createExternalMedia(
    container: Container,
    sectionId: string,
    source: string,
    externalMedia: { name: string; url: string },
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    return Promise.resolve(undefined);
  }

  bulkAddAssetTags: (assetIds: string[], tags: string[]) => Promise<AddAssetTagsResponseData[]>;

  addAssetTags(assetIds: string, tags: string[]): Promise<AddAssetTagsResponseData> {
    return;
  }

  listCustomFieldKeys(container: Container): Promise<CustomFieldKeyResponseData> {
    return Promise.resolve(listCustomFieldKeysResponseMockData);
  }

  addAssetCustomFields(
    assetIds: string[],
    container: Container,
    customFields: CustomFieldKeyValueDto[],
  ): Promise<void> {
    return Promise.resolve(undefined);
  }

  fetchAsset(assetId: string): Promise<Asset> {
    return Promise.resolve(assetsMockData[0]);
  }

  getAssetDetails(assetId: string): Promise<AssetDetails> {
    return Promise.resolve({
      tags: assetTagsMockData,
      customFieldValues: assetCustomFieldValuesMockData,
    });
  }

  listBrandfolderAssets(
    brandfolderId: string,
    options: ListOptions | undefined,
  ): Promise<AssetsListResultSet> {
    return Promise.resolve({
      assets: assetsMockData,
      attachments: attachmentsMockData,
    });
  }

  listCollectionAssets(
    collectionId: string,
    options: ListOptions | undefined,
  ): Promise<AssetsListResultSet> {
    return Promise.resolve({
      assets: assetsMockData,
      attachments: attachmentsMockData,
    });
  }

  listCollectionSectionAssets(
    collectionId: string,
    sectionId: string,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve(undefined);
  }

  listContainerSectionAssets(
    container: Container,
    sectionId: string,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve({
      data: {
        assets: assetsMockData,
        attachments: attachmentsMockData,
      },
      totalCount: assetsMockData.length,
      perPage: assetsMockData.length,
      currentPage: options.pagination.page || 1,
      nextPage: options.pagination.nextPage || null,
    });
  }

  listContainerSectionsAssets(
    container: Container,
    sections: Section[],
    options: ListOptions | undefined,
  ): Promise<{ sectionId: string; results: PagedResults<AssetsListResultSet> }[]> {
    return Promise.resolve([]);
  }

  listSectionAssets(
    sectionId: string,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve(undefined);
  }

  listContainerAssets(
    container: Container,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve({
      data: {
        assets: assetsMockData,
        attachments: attachmentsMockData,
      },
      totalCount: assetsMockData.length,
      perPage: assetsMockData.length,
      currentPage: options.pagination.page || 1,
      nextPage: options.pagination.nextPage || null,
    });
  }

  listContainerAssetsBase(
    container: Container,
    options: ListOptions | undefined,
  ): Promise<PagedResults<AssetsListResultSet>> {
    return Promise.resolve({
      data: {
        assets: assetsMockData,
        attachments: attachmentsMockData,
      },
      totalCount: assetsMockData.length,
      perPage: assetsMockData.length,
      currentPage: options.pagination.page || 1,
      nextPage: options.pagination.nextPage || null,
    });
  }

  bulkDownloadAssets(assetIds: string[], name?: string): Promise<BulkDownloadAssetsDto> {
    return Promise.resolve({
      data: {
        attachment_count: '0',
        manifest_digest: '',
        redirect_url: '',
      },
    });
  }
}

export function createFakeAssetsResponse(
  overrides: Partial<PagedResults<AssetsListResultSet>>,
): PagedResults<AssetsListResultSet> {
  return {
    data: {
      assets: assetsMockData,
      attachments: attachmentsMockData,
    },
    totalCount: overrides?.data?.assets?.length || assetsMockData.length,
    perPage: overrides?.data?.assets?.length || assetsMockData.length,
    currentPage: 1,
    nextPage: null,
    ...overrides,
  };
}

export function createFakeAssetsBaseResponse(
  overrides: Partial<PagedResults<AssetsListBaseResultSet>>,
): PagedResults<AssetsListBaseResultSet> {
  return {
    data: {
      assets: assetsMockData,
    },
    totalCount: overrides?.data?.assets?.length || assetsMockData.length,
    perPage: overrides?.data?.assets?.length || assetsMockData.length,
    currentPage: 1,
    nextPage: null,
    ...overrides,
  };
}
