import { DI_CONTAINER } from '../di-container';

export const APP_ROUTES_TOKEN = 'APP_ROUTES_TOKEN';

export type GetAppRoutes = () => {
  HOME: string;
  LANDING: string;
  AUTH_ERROR: string;
  INIT_ERROR: string;
};

export const ROUTES = {
  // Routes for Smartsheet Content Library Cuff Library
  CUFF_LANDING: '/:directId/content-library/landing',
  CUFF_HOME: '/:directId/content-library',
  CUFF_AUTH_ERROR: '/:directId/content-library/authentication-error',
  CUFF_INIT_ERROR: '/:directId/content-library/initialization-error',

  // Routes for Smartsheet Content Library App
  APP_LANDING: '/landing',
  APP_HOME: '/',
  APP_LOGIN: '/login',
  APP_AUTH_ERROR: '/authentication-error',
  INIT_ERROR: '/initialization-error',
};

export const getAppRoutes: GetAppRoutes = () => {
  const getAppRoutes: GetAppRoutes = DI_CONTAINER.get(APP_ROUTES_TOKEN);
  return getAppRoutes();
};
