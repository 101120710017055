import { BrandfolderApi } from './brandfolder-api';
import {
  AddAssetTagsResponseData,
  ApiFetchDataResponse,
  ApiListDataResponse,
  AssetDto,
  AssetOptions,
  AttachmentDto,
  AttachmentOptions,
  CustomFieldKeyDto,
  LabelDto
} from './model';

export class CLBrandfolderApi extends BrandfolderApi {
  async fetchAsset(
    apiKey: string,
    assetId: string,
    options?: AssetOptions,
  ): Promise<ApiFetchDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/private/smartsheet/content-library/api/assets/${assetId}`, options);
  }

  async requestUploadUrl(apiKey: string): Promise<{
    upload_url: string;
    object_url: string;
  }> {
    return await this.get(apiKey, '/v4/private/smartsheet/content-library/api/upload_requests', {
      batchRequests: false,
    });
  }

  async addAssetTags(
    apiKey: string,
    assetIds: string,
    tags: string[],
  ): Promise<AddAssetTagsResponseData> {
    const data = {
      attributes: tags.map((tag) => {
        return { name: tag };
      }),
    };
    return await this.post(apiKey, `/v4/private/smartsheet/content-library/api/assets/${assetIds}/tags`, { data });
  }

  async listAssetAttachments(
    apiKey: string,
    assetId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/private/smartsheet/content-library/api/assets/${assetId}/attachments`, options);
  }

  async fetchAttachment(
    apiKey: string,
    attachmentId: string,
    options?: AttachmentOptions,
  ): Promise<ApiFetchDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/attachments/${attachmentId}`, options);
  }

  /**
   * Retrieves the custom field keys for a collection.
   * No need to call the API for Content Library.
   *
   * @param {string} apiKey - The API key.
   * @param {string} collectionId - The ID of the collection.
   * @return {Promise<ApiListDataResponse<CustomFieldKeyDto>>} A promise that resolves to the custom field keys.
   */
  getCollectionCustomFieldKeys(
    apiKey: string,
    collectionId: string
  ): Promise<ApiListDataResponse<CustomFieldKeyDto>> {
      return Promise.resolve({ data: [], included: [] });
  }

  /**
   * Retrieves the labels for a collection.
   * No need to call the API for Content Library.
   *
   * @param {string} apiKey - The API key.
   * @param {string} collectionId - The ID of the collection.
   * @return {Promise<ApiListDataResponse<LabelDto>>} A promise that resolves to the labels for the collection.
   */
  getCollectionLabels(
    apiKey: string,
    collectionId: string
  ): Promise<ApiListDataResponse<LabelDto>> {
      return Promise.resolve({ data: [], included: [] });
  }
}
