import { put, select, takeEvery } from 'typed-redux-saga';
import { shiftSelectAssets, shiftSelectAssetsSuccess } from '../actions';
import {
  assetEntitySelectors,
  searchAssetsSelectors,
  selectAssetSelectors,
} from '../../../selectors';
import { Asset } from '@integration-frontends/integration/core/model';
import { AssetsListPagination } from '../../../common/assets-list/assets-list-state/assets-list-pagination';

const handler = function* (action: ReturnType<typeof shiftSelectAssets>) {
  const { asset } = action.payload;
  const assetEntities: Asset[] = yield select(assetEntitySelectors.selectAll);
  const paginatedResults: AssetsListPagination = yield select(
    searchAssetsSelectors.listSelectors.pagination,
  );
  const selectedAssets: Asset[] = yield select(selectAssetSelectors.assets);

  const paginatedAssetIds: string[][] = paginatedResults.pageAssetIds;
  const mergedAssetIds: string[] = [].concat(...paginatedAssetIds);
  const firstSelectedAssetId = selectedAssets[0]?.id;

  const firstSelectedAssetIndex = mergedAssetIds.findIndex((id) => id === firstSelectedAssetId);
  const currentSelectedAssetIndex = mergedAssetIds.findIndex((id) => id === asset.id);

  let selectedAssetIds: string[] = [];
  if (firstSelectedAssetIndex < currentSelectedAssetIndex) {
    selectedAssetIds = mergedAssetIds.slice(firstSelectedAssetIndex, currentSelectedAssetIndex + 1);
  } else {
    selectedAssetIds = mergedAssetIds.slice(currentSelectedAssetIndex, firstSelectedAssetIndex + 1);
  }

  const updatedSelectedAssets: Asset[] = assetEntities.filter((asset) =>
    selectedAssetIds.includes(asset.id),
  );

  yield put(shiftSelectAssetsSuccess({ assets: updatedSelectedAssets }));
};

export function* shiftSelectAssetsEffects() {
  yield takeEvery(shiftSelectAssets, handler);
}
