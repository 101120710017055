import { DEFAULT_PAGE_SIZE } from './common';

export interface AssetsListPagination {
  loading: boolean;
  currentPage: number;
  pageAssetIds: string[][];
  pageSize: number;
  totalCount: number;
  nextPage: number;
}

export function initAssetsListPagination(
  props: Partial<AssetsListPagination> = {},
): AssetsListPagination {
  return {
    loading: true,
    currentPage: 1,
    pageAssetIds: [],
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: null,
    nextPage: null,
    ...props,
  };
}

export function getPageCount(state: AssetsListPagination): number {
  return Math.ceil(state.totalCount / state.pageSize);
}

export function getPageFloor(state: AssetsListPagination): number {
  return state.currentPage * state.pageSize - state.pageSize + 1;
}

export function getPageCeil(state: AssetsListPagination): number {
  return Math.min(state.currentPage * state.pageSize, state.totalCount);
}

export function hasPreviousPage(state: AssetsListPagination): boolean {
  return state.currentPage > 1;
}

export function hasNextPage(state: AssetsListPagination): boolean {
  return state.currentPage < getPageCount(state);
}

export function incrementCurrentPage(state: AssetsListPagination): AssetsListPagination {
  return {
    ...state,
    currentPage: Math.min(state.currentPage + 1, getPageCount(state)),
  };
}

export function decrementCurrentPage(state: AssetsListPagination): AssetsListPagination {
  return {
    ...state,
    currentPage: Math.max(state.currentPage - 1, 1),
  };
}
