import { takeEvery } from 'redux-saga/effects';
import { addFilesProcessing, updateAssets } from '../actions';
import { put } from 'typed-redux-saga';
import { handleFilesProcessing } from './upload';

const handler = function* (action: ReturnType<typeof updateAssets>) {
  const { assetIds } = action.payload;
  yield put(addFilesProcessing({ assetIds: assetIds }));
  yield handleFilesProcessing();
};

export function* updateAssetsEffects() {
  yield takeEvery(updateAssets, handler);
}
