import { call, takeEvery } from 'typed-redux-saga';
import { selectAllAssetsError } from '../actions';
import { getObservabilityService } from '@integration-frontends/core';

const handler = function* (action: ReturnType<typeof selectAllAssetsError>) {
  yield call(getObservabilityService().addError, action.payload.error);
};

export function* selectAllAssetsErrorEffects() {
  yield takeEvery(selectAllAssetsError, handler);
}
