import {
  AbsoluteDimensions,
  areDimensionsEqual,
  AttachmentVariant,
  getMediaTypesExtensions,
  getMimeTypesFromMediaType,
  hasOffsets,
  MediaType,
  Offsets,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { AttachmentWithDto } from '../model';
import { any, compose, includes, toString } from 'ramda';

const BF_CDN_V1_BASE_URL = 'https://cdn.brandfolder.io';
const BF_CDN_V2_BASE_URL = 'https://cdn.bfldr.com';
const BF_CDN_STAGING_BASE_URLS = [
  'https://cdn-staging.bfldr.com',
  'https://cdn-staging.brandfolder.io',
];
// Cross-check with current list here (make sure to check latest version on master):
// https://github.com/brandfolder/terraform/blob/master/infra/squads/asset/prod/fastly-smart-cdn-v2/terragrunt.hcl#L16-L30
const BF_CDN_VANITY_BASE_URLS = [
  'images.gocompare.com',
  'dam.athabascau.ca',
  'images.hebcdn.com',
  'documents.lookout.com',
  'smartcdn.dam.gettyimages.com',
  'img.lumens.com',
  'assetcdn.achieve3000.com',
  'brandfolder.xero.com',
  'assets.rochesterregional.org',
  'cdn.hercampus.com',
  'cdn.theindustrybest.com',
  'cdn.scale.tech',
  'cdnbf.hvlgroup.com',
];

const isV1 = includes(BF_CDN_V1_BASE_URL);
const isV2 = (url) =>
  includes(BF_CDN_V2_BASE_URL, url) ||
  any(
    Boolean,
    BF_CDN_STAGING_BASE_URLS.map((cdnUrl) => includes(cdnUrl, url)),
  );
const isVanityUrl = (url) =>
  any(
    Boolean,
    BF_CDN_VANITY_BASE_URLS.map((cdnUrl) => includes(cdnUrl, url)),
  );
export const supportsCdnTransforms = (url: string) => isV1(url) || isV2(url) || isVanityUrl(url);
export const supportsCropping = isV2;

export interface CdnVariantOptions {
  size?: AbsoluteDimensions;
  crop?: AbsoluteDimensions & Offsets;
  mediaType?: MediaType;
}

// Generates a Brandfolder CDN url with the specified variations on the original attachment
export function createAttachmentCdnVariant(
  attachment: AttachmentWithDto,
  options: CdnVariantOptions,
): AttachmentVariant {
  const { crop, size, mediaType } = options;
  const roundedToString = compose(toString, Math.round);

  let variantUrl = new URL(attachment.url);

  if (mediaType) {
    variantUrl = new URL(setMediaType(attachment.url, mediaType));
  }

  if (size) {
    size.width &&
      size.width !== attachment.dimensions.width &&
      variantUrl.searchParams.set('width', roundedToString(size.width));
    size.height &&
      size.height !== attachment.dimensions.height &&
      variantUrl.searchParams.set('height', roundedToString(size.height));
  }

  if (
    crop &&
    (!areDimensionsEqual(crop, attachment.dimensions) || hasOffsets(crop)) &&
    isV2(attachment.url)
  ) {
    variantUrl.searchParams.set(
      'crop',
      `${roundedToString(crop.width)},${roundedToString(crop.height)},x${roundedToString(
        crop.offsetX,
      )},y${roundedToString(crop.offsetY)},safe`,
    );
  }

  const fileName = setFileNameExtension(attachment.filename, mediaType || attachment.mediaType);

  return {
    ...attachment,
    type: ResourceType.ATTACHMENT_VARIANT,
    attachmentId: attachment.id,
    dimensions: options.size,
    url: variantUrl.toString(),
    thumbnailUrl: variantUrl.toString(),
    mediaType: mediaType || attachment.mediaType,
    mimetype: getMimeTypesFromMediaType(mediaType || attachment.mediaType)[0],
    filename: fileName,
    name: fileName,
    extension: getMediaTypesExtensions([mediaType || attachment.mediaType])[0],
    dto: {
      ...attachment.dto,
      attributes: {
        ...attachment.dto.attributes,
        cdn_url: variantUrl.toString(),
        extension: getMediaTypesExtensions([mediaType || attachment.mediaType])[0],
        filename: fileName,
        mimetype: getMimeTypesFromMediaType(mediaType || attachment.mediaType)[0],
      },
    },
  };
}

export function setMediaType(url: string, mediaType: MediaType): string {
  if (!url) return null;

  const transformedUrl = new URL(url);
  const extension = getMediaTypesExtensions([mediaType])?.[0];

  if (!extension) return url;

  if (isV2(url)) {
    transformedUrl.searchParams.set('format', extension);
  } else {
    transformedUrl.pathname = `${removeExtension(transformedUrl.pathname)}.${extension}`;
  }
  return transformedUrl.toString().replace('auto=webp', ''); // auto=webp interferes with CDN transforms
}

function setFileNameExtension(name: string, mediaType: MediaType): string {
  if (!name) return null;

  const extension = getMediaTypesExtensions([mediaType])?.[0];

  if (!extension) return name;

  return `${removeExtension(name)}.${extension}`;
}

// TODO: use library?
function removeExtension(path: string): string {
  return path.replace(/\.[^/.]+$/, '');
}
