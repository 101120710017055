import {
  DI_CONTAINER,
  ILogger,
  LOGGER_TOKEN,
  getObservabilityService,
} from '@integration-frontends/core';
import {
  collectionEntityActions,
  containerCustomFieldActions,
  containerFileTypeAggregateActions,
  containerTagsActions,
  labelEntityActions,
  searchFilterEntityActions,
  sectionEntityActions,
} from '../../../common/entities-state';
import {
  Container,
  CONTAINER_REPO_TOKEN,
  IContainerRepo,
  ILabelRepo,
  ISearchFilterRepo,
  ISectionRepo,
  LABEL_REPO_TOKEN,
  ResourceType,
  SEARCH_FILTER_REPO_TOKEN,
  Section,
  SECTION_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import { prop } from 'ramda';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { searchAssetsActions, setEnforcedSearchParameters } from '../actions';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '../../../select-attachment';

function* handler(action: ReturnType<typeof searchAssetsActions.init>) {
  const { container, privateSectionSearch } = action.payload;

  yield all([initSearch(container, privateSectionSearch), initFilters(container)]);
}

function* initSearch(container: Container, privateSectionSearch?: boolean) {
  const sectionRepo: ISectionRepo = DI_CONTAINER.get(SECTION_REPO_TOKEN);
  const selectAttachmentOptions: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const { enforcedSearchParameters } = selectAttachmentOptions;
  if (enforcedSearchParameters) {
    yield put(setEnforcedSearchParameters({ enforcedSearchParameters }));
  }
  const isCollection = container.type === ResourceType.COLLECTION;

  let sections: Section[] = [];

  if (privateSectionSearch && isCollection) {
    sections = yield call(sectionRepo.listCollectionSectionsPrivate, container.id);
  } else {
    sections = yield call(sectionRepo.listContainerSections, container);
  }

  if (isCollection) {
    yield put(
      collectionEntityActions.collectionUpdated({
        ...container,
        sectionIds: sections.map(prop('id')),
      }),
    );
  }

  yield put(sectionEntityActions.sectionsReceived(sections));
  yield put(searchAssetsActions.sectionsLoaded({ sectionIds: sections.map(prop('id')) }));
  yield put(searchAssetsActions.initSuccess());
}

function* initFilters(container: Container) {
  const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);
  const labelRepo: ILabelRepo = DI_CONTAINER.get(LABEL_REPO_TOKEN);
  const searchFilterRepo: ISearchFilterRepo = DI_CONTAINER.get(SEARCH_FILTER_REPO_TOKEN);

  const [customFields, fileTypeAggregates, tags, labels, searchFilters] = yield all([
    call(tryPromiseOrDefault(containerRepo.getContainerCustomFields, []), container),
    call(tryPromiseOrDefault(containerRepo.getContainerFileTypeAggregates, []), container),
    call(tryPromiseOrDefault(containerRepo.getContainerTags, []), container),
    call(tryPromiseOrDefault(labelRepo.getContainerLabels, []), container),
    call(tryPromiseOrDefault(searchFilterRepo.listContainerSearchFilters, []), container),
  ]);
  yield put(containerCustomFieldActions.containerCustomFieldsReceived(customFields));
  yield put(
    containerFileTypeAggregateActions.containerFileTypeAggregatesReceived(fileTypeAggregates),
  );
  yield put(containerTagsActions.containerTagsReceived(tags));
  yield put(labelEntityActions.labelsReceived(labels));
  yield put(searchFilterEntityActions.searchFiltersReceived(searchFilters));
  yield put(searchAssetsActions.initSearchFiltersSuccess());
}

function tryPromiseOrDefault(fn, defaultValue: any) {
  const logger: ILogger = DI_CONTAINER.get(LOGGER_TOKEN);

  return async function () {
    try {
      return await fn.apply(null, arguments);
    } catch (e) {
      getObservabilityService().addError(e);
      return defaultValue;
    }
  };
}

export function* initEffects() {
  yield takeEvery(searchAssetsActions.init, function* (action) {
    yield handler(action);
  });
}
