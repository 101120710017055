import { AuthError } from './error';

export interface Identity {
  token: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

// API key for now, but will most likely add other methods in the near future
export type Credentials = string | unknown;

export const AUTHENTICATE_SERVICE_TOKEN = 'AUTHENTICATE_SERVICE_TOKEN';
export interface IAuthenticateService<T = Credentials> {
  authenticate: (credentials: T) => Promise<Identity | AuthError>;
  isAuthenticated: (token: string) => Promise<boolean>;
  refreshToken?: () => Promise<string>;
}
