import { datadogRum } from '@datadog/browser-rum';
import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
import { Identity } from '@integration-frontends/common/auth/core/model';
import { injectable } from 'inversify';
import {
  DatadogRUMConfig,
  IObservabilityService,
  OBSERVABILITY_SERVICE_TOKEN,
} from '../iobservability-service';
import { initDatadogRUM } from './init-datadog-rum';
import { sendIdentityDatadog } from './send-identity-datadog';
import { addErrorDatadog } from './add-error-datadog';
import { DI_CONTAINER } from '../../di-container';

@injectable()
export class ObservabilityServiceDatadogStrategy
  implements IObservabilityService<DatadogRUMConfig, Identity>
{
  initObservability = async (config: DatadogRUMConfig): Promise<void> => {
    return initDatadogRUM(config);
  };

  sendIdentity = async (identity: Identity, environment: Environment): Promise<void> => {
    return sendIdentityDatadog(identity, environment);
  };

  addError = async (error: Error): Promise<void> => {
    console.error(error);
    return addErrorDatadog(error);
  };

  setGlobalContextProperty(key: string, value: unknown): void {
    datadogRum.setGlobalContextProperty(key, value);
  }

  stopSessionRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }
}

export function getObservabilityService() {
  return DI_CONTAINER.get(OBSERVABILITY_SERVICE_TOKEN) as IObservabilityService;
}
