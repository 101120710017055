import {
  AuthError,
  CredentialsInvalidError,
  IAuthenticateService,
  Identity,
} from '@integration-frontends/common/auth/core/model';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  isError,
  UserDto,
} from '@integration-frontends/common/brandfolder-api';
import { inject, injectable } from 'inversify';

@injectable()
export class AuthenticationServiceTokenStrategy implements IAuthenticateService<string> {
  @inject(BRANDFOLDER_API_TOKEN)
  private brandfolderApi: BrandfolderApi;

  authenticate = async (apiKey: string): Promise<Identity | AuthError> => {
    const user = await this.getCurrentUserDto(apiKey);
    return user
      ? {
          token: apiKey,
          userId: user.id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.attributes.email,
        }
      : new CredentialsInvalidError();
  };

  isAuthenticated = (token: string): Promise<boolean> => {
    return this.getCurrentUserDto(token).then(Boolean);
  };

  refreshToken = async (): Promise<string> => {
    return await this.brandfolderApi.refreshAuthtoken();
  };

  private getCurrentUserDto = async (apiKey: string): Promise<UserDto> => {
    return this.brandfolderApi
      .whoAmI(apiKey)
      .then((res) => (isError(res) ? null : res.data))
      .catch(() => null);
  };
}
