import { createReducer } from '@reduxjs/toolkit';
import {
  addAssetTagFailure,
  addAssetTagSuccess,
  addAssetTagsComplete,
  addAssetTagsInit,
  addAssetTagsReset,
} from './actions';

export interface UploadTagsState {
  loading: boolean;
  tagsCount: number;
  totalAssets: number;
  uploadTagsSuccesCount: number;
  uploadStatusErrorCount: number;
  uploadTagsComplete: boolean;
}

export const uplodTagsInitialState: UploadTagsState = {
  loading: false,
  tagsCount: 0,
  totalAssets: 0,
  uploadTagsSuccesCount: 0,
  uploadStatusErrorCount: 0,
  uploadTagsComplete: false,
};

export const UPLOAD_TAGS_REDUCER = createReducer(uplodTagsInitialState, (builder) =>
  builder
    .addCase(addAssetTagsInit, (state, action) => {
      state.tagsCount = action.payload.tags.length;
      state.totalAssets = action.payload.totalAssets;
      state.loading = true;
    })
    .addCase(addAssetTagSuccess, (state) => {
      state.uploadTagsSuccesCount++;
    })
    .addCase(addAssetTagFailure, (state) => {
      state.uploadStatusErrorCount++;
    })
    .addCase(addAssetTagsComplete, (state) => {
      state.uploadTagsComplete = true;
      state.loading = false;
    })
    .addCase(addAssetTagsReset, (state) => {
      state.totalAssets = 0;
      state.tagsCount = 0;
      state.uploadTagsSuccesCount = 0;
      state.uploadStatusErrorCount = 0;
      state.uploadTagsComplete = false;
    }),
);
