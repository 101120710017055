import { takeEvery } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';
import { bulkRemoveAssetsFromCollectionsSuccess } from '../actions';
import { deselectAllAssets } from '../../../select-assets';
import { assetEntityActions } from '../../../common';
import { push } from 'redux-first-history';
import { getAppRoutes } from '@integration-frontends/core/app-routes';
import { Asset } from '@integration-frontends/integration/core/model';
import { assetEntitySelectors } from '../../../selectors';
import { FeaftureFlags_V2, isFeatureFlagEnabled_V2 } from '@integration-frontends/core';

function* handler(action: ReturnType<typeof bulkRemoveAssetsFromCollectionsSuccess>) {
  const { assetIds } = action.payload;
  yield put(assetEntityActions.assetsRemoved(assetIds));
  yield put(deselectAllAssets());
  const assets: Asset[] = yield select(assetEntitySelectors.selectAll);
  const numberOfAssets = assets.length;
  const hasUppyUploader = isFeatureFlagEnabled_V2(FeaftureFlags_V2.IsUnifiedUploaderEnabled);

  // This is handled on show page when uppy is not enabled
  if (!hasUppyUploader && numberOfAssets === 0) {
    yield put(push(getAppRoutes().LANDING));
  }
}

export function* bulkRemoveAssetsFromCollectionsSucessEffects() {
  yield takeEvery(bulkRemoveAssetsFromCollectionsSuccess, handler);
}
