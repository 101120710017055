/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  AuthError,
  Credentials,
  IAuthenticateService,
  Identity,
} from '@integration-frontends/common/auth/core/model';
import { IDENTITY_MOCK } from './identity-mock';

export class AuthenticationServiceMock implements IAuthenticateService {
  authenticate(credentials: Credentials): Promise<Identity | AuthError> {
    return Promise.resolve(IDENTITY_MOCK);
  }
  isAuthenticated(token: string): Promise<boolean> {
    return Promise.resolve(true);
  }
  refreshToken: () => Promise<string>;
}
