import {
  AssetsListPagination,
  initAssetsListPagination,
} from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { createAssetsListActions } from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { prop } from 'ramda';

export interface AssetsListState {
  showAttachments: boolean;
  pagination: AssetsListPagination;
}

export const assetsListInitialState: AssetsListState = {
  showAttachments: false,
  pagination: initAssetsListPagination(),
};

export function createAssetsListReducer(actions: ReturnType<typeof createAssetsListActions>) {
  return createReducer(assetsListInitialState, (builder) =>
    builder
      .addCase(actions.listInit, (state) => {
        state.pagination = initAssetsListPagination();
      })
      .addCase(actions.nextPage, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.nextPageBase, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.previousPage, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.previousPageBase, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.pageLoaded, (state, action) => {
        const { results } = action.payload;
        state.pagination.pageSize = results.perPage;
        state.pagination.totalCount = results.totalCount;
        state.pagination.currentPage = results.currentPage;
        state.pagination.pageAssetIds[results.currentPage] = results.data.assets.map(prop('id'));
        state.pagination.nextPage = results.nextPage;
        state.pagination.loading = false;
      })
      .addCase(actions.toggleShowAttachment, (state) => {
        state.showAttachments = !state.showAttachments;
      }),
  );
}

const setLoadingIfNotAllAssetsLoaded = (state: AssetsListState) => {
  if (pageAssetIdsCount(state) < state.pagination.totalCount) {
    state.pagination.loading = true;
  }
};

const pageAssetIdsCount = (state: AssetsListState) => {
  return state.pagination.pageAssetIds.reduce((sum, pageAssetIds) => sum + pageAssetIds.length, 0);
};
