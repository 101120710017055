import { takeEvery } from 'redux-saga/effects';
import { bulkDownloadAssetsInit, downloadError } from '../actions';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { put, call } from 'typed-redux-saga';
import { closeToast, createToast } from '@integration-frontends/common/notifications';
import { t } from '@lingui/macro';

const handler = function* (action: ReturnType<typeof bulkDownloadAssetsInit>) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const { assetIds, name, onDownloadError } = action.payload;
  const toastId = `download-asset-init-${Date.now()}`;

  yield put(
    createToast({
      id: toastId,
      type: 'info',
      toastContent: { contentText: t`Downloading ${assetIds.length} files` },
    }),
  );
  try {
    const bulkDownloadResponse = yield call(assetRepo.bulkDownloadAssets, assetIds, name);
    window.location.href = bulkDownloadResponse.data.redirect_url;
  } catch (error) {
    yield put(closeToast({ id: toastId }));
    if (onDownloadError) {
      onDownloadError();
    }
    yield put(downloadError());
  }
};

export function* bulkDownloadAssetsInitEffects() {
  yield takeEvery(bulkDownloadAssetsInit, handler);
}
