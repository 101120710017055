import { StateSelector, withPayloadType } from '@integration-frontends/common/utils/redux';
import {
  Container,
  EnforcedSearchParameters,
  ResourceBaseSortableProperty,
  SearchAssetsGroupBy,
  SearchParameters,
  SearchType,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';
import { createAssetsListBySectionActions } from '../../common/assets-list/assets-list-by-section-state';
import { createAssetsListActions } from '../../common/assets-list/assets-list-state';
import { SearchAssetsState } from './reducer';

export type SearchAssetsStateSelector = StateSelector<SearchAssetsState>;

export const init = createAction(
  'SEARCH_ASSETS_INIT',
  withPayloadType<{ container: Container; privateSectionSearch?: boolean }>(),
);
export const initSuccess = createAction('SEARCH_ASSETS_INIT_SUCCESS');
export const initSearchFiltersSuccess = createAction('SEARCH_ASSETS_FILTERS_INIT_SUCCESS');
export const clearSearch = createAction(
  'SEARCH_ASSETS_CLEAR',
  withPayloadType<{ executeSearch: boolean }>(),
);
export const baseClearSearch = createAction(
  'SEARCH_ASSETS_CLEAR_BASE',
  withPayloadType<{ executeSearch: boolean }>(),
);
export const search = createAction(
  'SEARCH_ASSETS',
  withPayloadType<{
    searchParams: SearchParameters;
    sortOptions?: SortOptions<ResourceBaseSortableProperty>;
  }>(),
);
export const baseSearch = createAction(
  'SEARCH_ASSETS_BASE',
  withPayloadType<{
    searchParams: SearchParameters;
    sortOptions?: SortOptions<ResourceBaseSortableProperty>;
    searchType?: SearchType;
  }>(),
);
export const searchSuccess = createAction(
  'SEARCH_ASSETS_SUCCESS',
  withPayloadType<{ resultIds: string[] }>(),
);
export const searchRefresh = createAction('SEARCH_REFRESH');
export const searchError = createAction(
  'SEARCH_ERROR',
  withPayloadType<{ error?: unknown } | undefined>(),
);
export const clearError = createAction('SEARCH_CLEAR_ERROR');
export const loadCustomFieldValues = createAction(
  'LOAD_CUSTOM_FIELD_VALUES',
  withPayloadType<{ customFieldId: string }>(),
);

export const changePageSize = createAction(
  'SEARCH_ASSETS_CHANGE_PAGE_SIZE',
  withPayloadType<{ pageSize: number }>(),
);

export const setEnforcedSearchParameters = createAction(
  'SEARCH_ASSETS_SET_ENFORCED_SEARCH_PARAMETERS',
  withPayloadType<{ enforcedSearchParameters: EnforcedSearchParameters }>(),
);

export const toggleShowAttachments = createAction('SEARCH_ASSETS_LIST_SHOW_ATTACHMENT');

export const downloadAttachment = createAction('SEARCH_ASSETS_LIST_DOWNLOAD_ATTACHMENT');

const assetsListActions = createAssetsListActions('SEARCH_ASSETS');
const assetsListBySectionActions = createAssetsListBySectionActions('SEARCH_ASSETS');

export const changeGroupBy = createAction(
  'SEARCH_ASSETS_LIST_CHANGE_GROUP_BY',
  withPayloadType<{ groupBy: SearchAssetsGroupBy }>(),
);

export const smarContentLibrarySearchInit = createAction(
  'SMAR_CONTENT_LIBRARY_SEARCH_INIT',
  withPayloadType<{ container: Container; searchParams?: SearchParameters }>(),
);

export const searchAssetsActions = {
  init,
  initSuccess,
  initSearchFiltersSuccess,
  clearSearch,
  baseClearSearch,
  search,
  baseSearch,
  searchSuccess,
  clearError,
  searchError,
  changePageSize,
  changeGroupBy,
  toggleShowAttachments,
  downloadAttachment,
  smarContentLibrarySearchInit,
  ...assetsListActions,
  ...assetsListBySectionActions,
};
