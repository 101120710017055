import classNames from 'classnames';
import React from 'react';
import { Checkbox, CheckedState, TypeRampV2 } from '@smartsheet/lodestar-core';
import { BFStandardCheckboxProps } from './bf-standard-checkbox';
import './bf-standard-checkbox-smartsheet.scss';

export function BFStandardCheckboxSmartsheet({
  checked,
  className,
  label,
  labelClassName,
  onChange,
  onClick,
  style,
  disabled,
  ...restProps
}: BFStandardCheckboxProps) {
  const checkedState: CheckedState = checked ? 'checked' : 'unchecked';

  const checkBoxLabel = () => {
    return (
      <TypeRampV2 system="lodestar" variant="bodyStrong">
        {label}
      </TypeRampV2>
    );
  };

  return (
    <div
      className={classNames('flex items-center', className)}
      style={style}
      data-testid={restProps['data-testid']}
    >
      <Checkbox
        className={classNames('smar-checkbox', labelClassName)}
        label={checkBoxLabel()}
        checkedState={checkedState}
        onClick={(e) => {
          onChange && onChange(e as any);
          onClick && onClick(e as any);
        }}
        isDisabled={disabled}
      />
    </div>
  );
}
