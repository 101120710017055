import {
  Attachment,
  AttachmentListResultSet,
  attachmentsMockData,
  Container,
  IAttachmentRepo,
  ListOptions,
  PagedResults,
} from '@integration-frontends/integration/core/model';

export class AttachmentRepoMock implements IAttachmentRepo {
  getAttachment(attachmentId: string): Promise<Attachment> {
    return Promise.resolve(attachmentsMockData[0]);
  }

  listAssetAttachments(assetId: string): Promise<Attachment[]> {
    return Promise.resolve(attachmentsMockData);
  }

  listContainerAttachments(
    container: Container,
    options: ListOptions,
  ): Promise<PagedResults<AttachmentListResultSet>> {
    return Promise.resolve({
      data: {
        attachments: attachmentsMockData,
      },
      totalCount: attachmentsMockData.length,
      perPage: attachmentsMockData.length,
      nextPage: options.pagination.nextPage || null,
      currentPage: options.pagination.page || 1,
    });
  }
}

export function createMockAttachmentResponse(
  overrides: Partial<PagedResults<AttachmentListResultSet>> = {},
): PagedResults<AttachmentListResultSet> {
  return {
    data: {
      attachments: attachmentsMockData,
    },
    totalCount: overrides?.data?.attachments?.length || attachmentsMockData.length,
    perPage: overrides?.data?.attachments?.length || attachmentsMockData.length,
    nextPage: null,
    currentPage: 1,
    ...overrides,
  };
}
