import { IntegrationEntitiesStateSelector } from '@integration-frontends/integration/core/application/common';
import { createIntegrationEntitiesSelectors } from '@integration-frontends/integration/core/application/common/entities-state/selectors';
import { IntegrationRootState } from './integration-core-application';
import { createPlaceAttachmentSelectors } from './place-attachment/state';
import { createSearchAssetsSelectors, SearchAssetsStateSelector } from './search-assets/state';
import {
  createSelectAttachmentSelectors,
  SelectAttachmentStateSelector,
} from './select-attachment/state';
import { createUploadAssetSelectors } from './upload-assets/state/selectors';
import { createDownloadAssetsSelectors } from './download-assets';
import { createContentLibraryUploadAssetSelectors } from './content-library-upload-assets/state/selectors';
import { createUploadTagsSelectors } from './upload-tags/state/selectors';
import {
  createSelectAssetSelector,
  SelectAssetStateSelector,
} from './select-assets/state/selectors';

/**
 * Entity selectors
 */

export const integrationEntitiesStateSelector: IntegrationEntitiesStateSelector = (
  state: IntegrationRootState,
) => state.integrationApplication.entities;

export const entitiesSelectors = createIntegrationEntitiesSelectors(
  integrationEntitiesStateSelector,
);

const {
  asset,
  assetCustomFieldValue,
  assetTag,
  attachment,
  brandfolder,
  collection,
  container,
  containerCustomFields,
  containerFileTypeAggregates,
  containerTags,
  label,
  organization,
  searchFilter,
  section,
} = entitiesSelectors;

export const assetEntitySelectors = asset;
export const assetCustomFieldValueEntitySelectors = assetCustomFieldValue;
export const assetTagEntitySelectors = assetTag;
export const attachmentEntitySelectors = attachment;
export const assetAttachmentsSelector = asset.assetAttachments;
export const brandfolderEntitySelectors = brandfolder;
export const collectionEntitySelectors = collection;
export const containerCustomFieldEntitySelectors = containerCustomFields;
export const containerFileTypeAggregatesEntitySelectors = containerFileTypeAggregates;
export const containerSelectors = container;
export const containerTagEntitySelectors = containerTags;
export const labelEntitySelectors = label;
export const organizationEntitySelectors = organization;
export const searchFilterEntitySelectors = searchFilter;
export const sectionEntitySelectors = section;

/**
 * Feature selectors
 */

// Content library upload assets
export const contentLibraryUploadAssetsSelectors = createContentLibraryUploadAssetSelectors(
  (state: IntegrationRootState) => state.integrationApplication.contentLibraryUploadAssets,
);

// Upload assets
export const uploadAssetsSelectors = createUploadAssetSelectors(
  (state: IntegrationRootState) => state.integrationApplication.uploadAssets,
  entitiesSelectors,
);

//Download assets
export const downloadAssetsSelectors = createDownloadAssetsSelectors(
  (state: IntegrationRootState) => state.integrationApplication.downloadAssets,
);

// Place attachment
export const placeAttachmentSelectors = createPlaceAttachmentSelectors(
  (state: IntegrationRootState) => state.integrationApplication.placeAttachment,
);

// Select attachment
export const selectAttachmentStateSelector: SelectAttachmentStateSelector = (
  root: IntegrationRootState,
) => root.integrationApplication.selectAttachment;

export const selectAttachmentSelectors = createSelectAttachmentSelectors(
  selectAttachmentStateSelector,
);

// Select asset
export const selectAssetStateSelector: SelectAssetStateSelector = (root: IntegrationRootState) =>
  root.integrationApplication.selectAsset;

export const selectAssetSelectors = createSelectAssetSelector(selectAssetStateSelector);

// Search assets
export const searchAssetsStateSelector: SearchAssetsStateSelector = (root: IntegrationRootState) =>
  root.integrationApplication.searchAssets;

export const searchAssetsSelectors = createSearchAssetsSelectors(
  searchAssetsStateSelector,
  entitiesSelectors,
);

//upload tags
export const uploadTagsSelectors = createUploadTagsSelectors(
  (state: IntegrationRootState) => state.integrationApplication.uploadTags,
);
