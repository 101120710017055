import { equals, flatten, intersection } from 'ramda';
import {
  ALL_FILE_TYPES,
  FILE_TYPE_EXTENSIONS,
  FILE_TYPE_MIME_TYPES,
  FileType,
  getFileTypeFromMimeType,
} from './file';
import {
  ALL_IMAGE_TYPES,
  getImageTypeFromMimeType,
  IMAGE_TYPE_EXTENSIONS,
  IMAGE_TYPE_MIME_TYPES,
  ImageType,
} from './image';
import {
  ALL_VIDEO_TYPES,
  getVideoTypeFromMimeType,
  VIDEO_TYPE_EXTENSIONS,
  VIDEO_TYPE_MIME_TYPES,
  VideoType,
} from './video';
import { getObservabilityService } from '@integration-frontends/core';

export type Extension = string;
export type MimeType = string;
export type UnknownMediaType = { extension?: string; mimeType?: string };
export type KnownMediaType = FileType | ImageType | VideoType;
export type MediaType = KnownMediaType | UnknownMediaType;
export const ALL_MEDIA_TYPES: MediaType[] = [
  ...ALL_FILE_TYPES,
  ...ALL_IMAGE_TYPES,
  ...ALL_VIDEO_TYPES,
];
export const ALL_MEDIA_EXTENSIONS = {
  ...FILE_TYPE_EXTENSIONS,
  ...IMAGE_TYPE_EXTENSIONS,
  ...VIDEO_TYPE_EXTENSIONS,
};

export function isKnownMediaType(mediaType: MediaType): mediaType is KnownMediaType {
  return mediaType && !(<UnknownMediaType>mediaType).extension;
}

export function areEqual(mediaType1: MediaType, mediaType2: MediaType): boolean {
  if (!mediaType1 || !mediaType2) return false;

  return isKnownMediaType(mediaType1)
    ? isKnownMediaType(mediaType2) && mediaType1 === mediaType2
    : !isKnownMediaType(mediaType2) &&
        (mediaType1.mimeType === mediaType2.mimeType ||
          mediaType1.extension === mediaType2.extension);
}

export function getMediaTypesExtensions(mediaTypes: MediaType[]): Extension[] {
  if (!mediaTypes) return [];

  return flatten(
    mediaTypes.map((mediaType) =>
      isKnownMediaType(mediaType)
        ? FILE_TYPE_EXTENSIONS[mediaType] ||
          IMAGE_TYPE_EXTENSIONS[mediaType] ||
          VIDEO_TYPE_EXTENSIONS[mediaType]
        : mediaType.extension,
    ),
  );
}

export function getExtensionMediaType(extension: Extension): MediaType {
  return (
    (Object.entries(ALL_MEDIA_EXTENSIONS).find(([_, extensions]) =>
      extensions.includes(extension.toLocaleLowerCase()),
    )?.[0] as MediaType) || ({ extension } as UnknownMediaType)
  );
}

export function getMediaTypesMimeTypes(mediaTypes: MediaType[]): MimeType[] {
  return flatten(mediaTypes.map(getMimeTypesFromMediaType));
}

export function getMimeTypesFromMediaType(mediaType: MediaType): MimeType[] {
  return isKnownMediaType(mediaType)
    ? FILE_TYPE_MIME_TYPES[mediaType] ||
        IMAGE_TYPE_MIME_TYPES[mediaType] ||
        VIDEO_TYPE_MIME_TYPES[mediaType]
    : [];
}

export function getImageMediaTypes(mediaTypes: MediaType[]): ImageType[] {
  return intersection(mediaTypes, ALL_IMAGE_TYPES) as ImageType[];
}

export function isImageType(mediaType: MediaType): boolean {
  return ALL_IMAGE_TYPES.includes(mediaType as ImageType);
}

export function isVideoType(mediaType: MediaType): boolean {
  return ALL_VIDEO_TYPES.includes(mediaType as VideoType);
}

export function getVideoMediaTypes(mediaTypes: MediaType[]): VideoType[] {
  return intersection(mediaTypes, ALL_VIDEO_TYPES) as VideoType[];
}

export function getMimeTypeMediaType(mimeType: string, extension?: string): MediaType {
  return (
    getFileTypeFromMimeType(mimeType) ||
    getImageTypeFromMimeType(mimeType) ||
    getVideoTypeFromMimeType(mimeType) || {
      extension: extension || getExtensionFromMimeType(mimeType),
      mimeType,
    }
  );
}

function getExtensionFromMimeType(mimeType: string): string {
  try {
    return mimeType.split('/').pop();
  } catch (e) {
    getObservabilityService().addError(e);
  }
}

export function isImage(mimeType: string): boolean {
  return !!getMediaTypesMimeTypes(ALL_IMAGE_TYPES).find(equals(mimeType));
}
