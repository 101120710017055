import { sendSegmentAction } from '@integration-frontends/common/analytics';
import {
  ANALYTICS_SERVICE_TOKEN,
  DI_CONTAINER,
  IAnalyticsService,
  ILogger,
  LOGGER_TOKEN,
  getObservabilityService,
} from '@integration-frontends/core';
import {
  addFileTypeFilters,
  Asset,
  ASSET_REPO_TOKEN,
  AssetsListResultSet,
  Attachment,
  Container,
  filterSectionsByContainer,
  getMediaTypesExtensions,
  IAssetRepo,
  IMediaTypeSupportService,
  ListOptions,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  PagedResults,
  ResourceBaseSortableProperty,
  SearchAssetsGroupBy,
  SearchParameters,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { prop } from 'ramda';
import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  assetEntityActions,
  attachmentEntityActions,
  IntegrationEntitiesSelectors,
} from '../../../common';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '../../../select-attachment';
import { search, searchAssetsActions, searchError, searchRefresh, searchSuccess } from '../actions';
import { SearchAssetsSelectors } from '../selectors';

const DEFAULT_SORT_OPTIONS: SortOptions = {
  direction: SortDirection.Asc,
  field: ResourceBaseSortableProperty.CreatedAt,
};

export const SEARCH_DEBOUNCE = 500;

const handler = function* (
  action: ReturnType<typeof search>,
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  let searchParams: SearchParameters;
  const { searchParams: searchParamsFromPayload, sortOptions } = action.payload;
  searchParams = { ...searchParamsFromPayload };
  const selectAttachmentOptions: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const analyticsService: IAnalyticsService = DI_CONTAINER.get(ANALYTICS_SERVICE_TOKEN);

  const pageSize = yield select(searchAssetsSelectors.pageSize);
  const container = yield select(searchAssetsSelectors.container);
  const groupBy = yield select(searchAssetsSelectors.groupBy);

  const { searchParameters, enforcedSearchParameters } = selectAttachmentOptions;
  if (searchParameters) {
    searchParams = {
      ...searchParameters,
      ...searchParams,
      query: (searchParameters.query || '') + (searchParams.query || ''),
    };
  }
  if (enforcedSearchParameters) {
    searchParams = {
      ...enforcedSearchParameters,
      ...searchParams,
      query: (enforcedSearchParameters.query || '') + (searchParams.query || ''),
    };
  }

  if (searchParams.query) {
    analyticsService.trackEvent('SearchExecute');
  }

  if (groupBy === SearchAssetsGroupBy.Section) {
    yield handleBySection(
      action,
      searchAssetsSelectors,
      entitiesSelectors,
      container,
      searchParams,
      pageSize,
      sortOptions,
    );
  } else {
    yield handleByContainer(
      action,
      searchAssetsSelectors,
      entitiesSelectors,
      container,
      searchParams,
      pageSize,
      sortOptions,
    );
  }
};

const handleByContainer = function* (
  action: ReturnType<typeof search>,
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
  container,
  searchParams,
  pageSize,
  sortOptions,
) {
  const results: PagedResults<AssetsListResultSet> = yield call(
    fetchContainerAssets,
    container,
    searchParams,
    pageSize,
    sortOptions,
  );

  const { assets, attachments } = results.data;

  yield put(searchAssetsActions.listInit());
  yield put(searchAssetsActions.pageLoaded({ results }));

  yield put(assetEntityActions.assetsReceived(assets));
  yield put(attachmentEntityActions.attachmentsReceived(attachments));

  yield put(sendSegmentAction({ event: 'searchAssetsSuccess' }));
  yield put(searchSuccess({ resultIds: assets.map(prop('id')) }));
};

const handleBySection = function* (
  action: ReturnType<typeof search>,
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
  container,
  searchParams,
  pageSize,
  sortOptions,
) {
  const sections = yield select(
    createSelector(entitiesSelectors.section.selectAll, (sections) =>
      sections.filter(filterSectionsByContainer(container)),
    ),
  );

  const sectionsPagedResults: {
    sectionId: string;
    results: PagedResults<{ assets: Asset[]; attachments: Attachment[] }>;
  }[] = yield all(
    sections.map(function* (section) {
      const results: PagedResults<AssetsListResultSet> = yield call(
        fetchSectionAssets,
        container,
        section.id,
        searchParams,
        pageSize,
        sortOptions,
      );

      return {
        sectionId: section.id,
        results,
      };
    }),
  );

  const assets = sectionsPagedResults.flatMap(({ results }) => results.data.assets);
  const attachments = sectionsPagedResults.flatMap(({ results }) => results.data.attachments);

  yield all(
    sectionsPagedResults.map((result) => put(searchAssetsActions.sectionPageLoaded(result))),
  );

  yield put(assetEntityActions.assetsReceived(assets));
  yield put(attachmentEntityActions.attachmentsReceived(attachments));
  yield put(sendSegmentAction({ event: 'searchAssetsSuccess' }));
  yield put(searchSuccess({ resultIds: assets.map(prop('id')) }));
};

function fetchSectionAssets(
  container: Container,
  sectionId: string,
  searchParams: SearchParameters,
  pageSize: number,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const options: ListOptions = {
    searchParams: addFileTypeFilters(
      searchParams,
      getMediaTypesExtensions(mediaTypeSupportService.getAllowedMediaTypes()),
    ),
    pagination: { perPage: pageSize },
    sort: sortOptions || DEFAULT_SORT_OPTIONS,
  };
  return assetRepo.listContainerSectionAssets(container, sectionId, options);
}

function fetchContainerAssets(
  container: Container,
  searchParams: SearchParameters,
  pageSize: number,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const options: ListOptions = {
    searchParams: addFileTypeFilters(
      searchParams,
      getMediaTypesExtensions(mediaTypeSupportService.getAllowedMediaTypes()),
    ),
    pagination: { perPage: pageSize },
    sort: sortOptions || DEFAULT_SORT_OPTIONS,
  };

  return assetRepo.listContainerAssets(container, options);
}

export function* searchEffects(
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  yield takeLatest(search, function* (action) {
    const logger: ILogger = DI_CONTAINER.get(LOGGER_TOKEN);
    yield delay(SEARCH_DEBOUNCE);

    while (true) {
      try {
        yield handler(action, searchAssetsSelectors, entitiesSelectors);
      } catch (error) {
        yield put(searchError({ error }));

        yield call(getObservabilityService().addError, error);
      }

      yield take(searchRefresh);
    }
  });
}
