import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const addAssetTagsInit = createAction(
  'ADD_ASSET_TAGS_INIT',
  withPayloadType<{
    assetIds: string[];
    tags: string[];
    totalAssets: number;
    onAddAssetTagError?: () => void;
  }>(),
);
export const addAssetTagSuccess = createAction('ADD_ASSET_TAG_SUCCESS');
export const addAssetTagFailure = createAction('ADD_ASSET_TAG_FAILURE');
export const addAssetTagsComplete = createAction('ADD_ASSET_TAGS_COMPLETE');
export const addAssetTagsReset = createAction('ADD_ASSET_TAGS_RESET');
